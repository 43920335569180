import React from "react"
import EventLayout from "../../components/layouts/eventLayout"

const HalloweenIndexPage = props => (
  <EventLayout pageTitle="Trick or Treat - Kid's Safety Day">
    <h2>Saturday, October 26, 2025 – Information Coming Soon</h2>
     {/* --
    <h2>Saturday, October 28, 2023, from 5:00 pm until 6:30 pm - South Newton Street</h2><br />
    <p>
      The Claxton Downtown Development Authority in partnership with the Claxton-Evans County Chamber of Commerce is excited to announce plans for the
      11th Annual “Trick or Treat with Local Merchants & Kid’s Safety Day” event scheduled for Saturday, October 28, 2023, 5:00-6:30PM. 
      <br /><br />
      This year’s event will be held on South Newton Street (which will be closed to traffic during event hours). We are asking local businesses/organizationstoparticipate. Your participation 
      will include the placement of a table on Newton Street, for the purpose of trick or treating, decorated as you choose. If you wish, your table could include a game or other activity, 
      such as face painting. In addition, various agencies and organizations will be providing fire safety information, bicycle helmet safety information, nutrition and healthy eating 
      information, a Costume Contest, and much more.
      <br /><br />
      <b>You will also be responsible for your table and chairs and any decorations you would like to use as well as candy to pass out.</b>
      <br /><br />
      If you would like to participate, please complete this form and return as indicated. The names of all participating businesses will be included in the advertisements for this event in The Claxton Enterprise.
      <br /><br />
      <b>~Deadline to respond and be included in print materials is Wednesday, October 12th~</b>
    </p>
   
    <div css={{margin: `2rem 0`}}>
      <a css={{
        display: "inline-block",
        marginTop: "0.66rrem",
        backgroundColor: "rgb(9, 37, 86)",
        color: "#fff",
        textDecoration: "none",
        padding: "0.667rem",
        borderRadius: "2px",
      }} href="/docs/2022-Trick-or-Treat-Letter.pdf">2022 Trick or Treat & Kids Safety Day Application Letter</a>
    </div>
    --*/}
  </EventLayout>
)

export default HalloweenIndexPage

